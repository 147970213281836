<template>
  <button type="button" class="text-icon back-icon" @click="$emit('close')">
    <Icon :path="mdiChevronLeft" />
    <span class="text">{{ title }}</span>
  </button>
</template>

<script>
  import { mdiChevronLeft } from "@mdi/js";

  export default {
    data() {
      return {
        mdiChevronLeft,
      };
    },
    props: {
      title: {
        default: null,
      },
    },
    emits: ["close"],
  };
</script>
