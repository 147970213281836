<template>
  <div>
    <p>
      <slot name="message"></slot>
    </p>

    <p>
      There <span v-if="affectedOrders.length === 1">is</span>
      <span v-else>are</span>
      <span class="order-count"> {{ affectedOrders.length }} </span>outstanding
      order{{ affectedOrders.length == 1 ? "" : "s" }} with a total value of
      <span class="order-count">
        {{
          this.formatMoney(
            affectedOrders
              .map((x) => x.grossRevenue)
              .reduce((partialSum, a) => partialSum + a, 0)
          )
        }}
      </span>
      which will be affected.
    </p>
    <p>
      <strong>
        If you continue,
        {{ affectedOrders.length == 1 ? "this order" : "these orders" }} will be
        cancelled and the user notified.
      </strong>
    </p>
    <button class="confirm-button" @click="handleConfirmCancellation">
      Confirm &amp; cancel orders
    </button>
    <button @click="$emit('close')">Do not cancel orders</button>
  </div>
</template>

<script>
  export default {
    props: {
      affectedOrders: {
        default: null,
      },
    },
    emits: ["close", "saveExistingItemConfirmed"],
    methods: {
      handleConfirmCancellation() {
        this.$emit("saveExistingItemConfirmed");
        this.$emit("close");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .order-count {
    font-size: 1.1rem;
    font-weight: bold;
    background: $col_alpha-lightest;
    display: inline-block;
    padding: 0.25rem 0.25rem;
    margin: 0 0.25rem;
  }
  .confirm-button {
    margin-right: 1rem;
  }
</style>
